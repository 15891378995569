import React from 'react'
import { Layout } from '../components/AppLayout'
import { motion } from 'framer-motion'
import { WhyRainDropBanner } from '../components/WhyRainDropBanner'
import { AppwhyNow } from '../components/AppwhyNow'
import { AppLeadingbyExperience } from '../components/AppLeadingbyExperience'
import { AppRaindropIsDesigned } from '../components/AppRaindropIsDesigned'
import { AppDigitization } from '../components/AppDigitization'
import { RDHelmet } from '../components/RDHelmet'

const whyRaindrop = () => {
  const schemaMarkup = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Raindrop Systems Inc',
    alternateName: 'Raindrop Systems Inc',
    url: 'https://raindrop.com/',
    logo: 'https://storage.googleapis.com/raindroppublic/website_data/raindrop_icon.svg',
    description:
      'Raindrop offers an AI-enabled enterprise cloud based spend management software focused on cost savings. Get started with free tools, and upgrade as you grow.',
    image: 'https://storage.googleapis.com/raindroppublic/website_data/OurServices.jpg',
    email: 'info@raindrop.com',
    contactPoint: [
      { '@type': 'ContactPoint', telephone: '1-408-899-2224', contactType: 'Business', areaServed: 'United States' },
    ],
    address: {
      '@type': 'Corporate',
      addressLocality: ' Santa Clara',
      addressRegion: ' 2350 Mission College Boulevard, CA',
      postalCode: '95054',
      addressCountry: 'United States',
    },
    sameAs: [
      'https://www.facebook.com/Raindrop-Systems-Inc-103810868375793',
      'https://twitter.com/saas_raindrop',
      'https://www.instagram.com/raindrop_spendmanagement/?igshid=1mn6sbd70aybs',
      'https://www.linkedin.com/company/raindropsystemsinc/',
    ],
  }
  return (
    <Layout>
      <RDHelmet
        subtitle={'Why Raindrop'}
        contentDescription="Raindrop offers an AI-enabled enterprise cloud based spend management software focused on cost savings. Get started with free tools, and upgrade as you grow."
        contentKeywords="content='Business spend management software, SaaS spend management software, Enterprise spend management software, Spend management software market, Spend management software companies, Business spend management platform, Artificial intelligence software, AI-based spend management solution'"
        contentOG="Cloud-Based Business Spend Management Platform | Try Raindrop Free"
        contentOgDescription="Raindrop offers an AI-enabled enterprise cloud based spend management software focused on cost savings. Get started with free tools, and upgrade as you grow."
        contentOgUrl="https://raindrop.com/whyRaindrop"
        schemaMarkup={schemaMarkup}
      />
      <motion.div
        animate={{ y: [0, 50, 0], x: [0, 50, 0], rotate: [0, 90, 0] }}
        style={{ width: '200px', opacity: 0.4, position: 'absolute', left: '10vw', top: '100vw' }}
        transition={{
          duration: 32,
          ease: 'easeInOut',
          loop: Infinity,
          repeatDelay: 0,
        }}
      >
        <img src="https://storage.googleapis.com/raindroppublic/website_data/bubble.svg" className="bubble" />
      </motion.div>
      <WhyRainDropBanner />
      <AppwhyNow />
      <motion.div
        animate={{ y: [0, 50, 0], x: [0, 50, 0], rotate: [0, 90, 0] }}
        style={{ width: '200px', opacity: 0.4, position: 'absolute', left: '10vw', top: '100vw' }}
        transition={{
          duration: 32,
          ease: 'easeInOut',
          loop: Infinity,
          repeatDelay: 0,
        }}
      >
        <img src="https://storage.googleapis.com/raindroppublic/website_data/bubble.svg" className="bubble" />
      </motion.div>
      <AppLeadingbyExperience />
      <motion.div
        animate={{ y: [0, 50, 0], x: [0, 50, 0], rotate: [0, 90, 0] }}
        style={{ width: '200px', opacity: 0.4, position: 'absolute', left: '10vw', top: '100vw' }}
        transition={{
          duration: 32,
          ease: 'easeInOut',
          loop: Infinity,
          repeatDelay: 0,
        }}
      >
        <img src="https://storage.googleapis.com/raindroppublic/website_data/bubble.svg" className="bubble" />
      </motion.div>
      <AppRaindropIsDesigned />
      <AppDigitization />
    </Layout>
  )
}
export default whyRaindrop
